import { Box, Flex, Spacer, Text, VStack } from '@chakra-ui/react';
import {
  Button,
  Divider,
  DividerLook,
  IconButton,
  Icons,
  SvgSpriteConsumer,
} from '@pluxee-design-system/core';
import { CheckboxGroupControl, InputControl, SelectControl } from 'common/forms';
import { useFormikContext } from 'formik';
import { PosTypeEnum } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';
import { get } from 'lodash';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { TerminalConfig } from 'types/config';
import { OnboardingValues } from '../types';
import ProductWithLogo from './ProductWithLogo';

interface POSRowProps extends I18nProps {
  config: TerminalConfig;
  children?: ReactNode;
  status?: ReactNode;
  transfer?: ReactNode;
  name: string;
  idPrefix?: string;
  index: number;
  onRemove?: () => void;
  isBoInfoVisible?: boolean;
  isOnboarding?: boolean;
  isReadOnly?: boolean;
  isRemovable?: boolean;
  isDisabled?: boolean;
  isProductsDisabled?: boolean;
  isProductsExpanded?: boolean;
  showDivider?: boolean;

  products: any[];
}

const POSRow = ({
  config,
  children,
  idPrefix = '',
  index,
  isBoInfoVisible = false,
  isDisabled = false,
  isReadOnly = false,
  isProductsDisabled = false,
  isProductsExpanded = true,
  name,
  onRemove,
  products,
  showDivider = false,
  status,
  transfer,
  t,
}: POSRowProps) => {
  const [productsExpanded, expandProducts] = useState(isProductsExpanded);
  const toggleProducts = useCallback(() => expandProducts((prev) => !prev), [expandProducts]);
  const { values } = useFormikContext<OnboardingValues>();
  const isAppType = get(values, `${name}deviceType`) === PosTypeEnum.App;
  const selectedProducts = get(values, `${name}productIds`);
  const productsCount = useMemo(
    () => Object.values(selectedProducts || {}).filter((x) => x).length,
    [selectedProducts],
  );
  const devices = useMemo(
    () =>
      Object.values(PosTypeEnum).map((val) => ({
        value: val,
        label: t(
          `location_add.form.deviceTypeOptions.${val}`,
          val.replace(/./, (c) => c.toUpperCase()),
        ),
      })),
    [t],
  );

  const removeButton = (
    <Button
      type="button"
      onClick={onRemove}
      variant="secondaryTextOnly"
      id={`${idPrefix}${name}deleteButton`}
      size="md"
      alignSelf="flex-end"
      leftIcon={<SvgSpriteConsumer size={24} svgId={Icons.TRASH24} />}
    >
      {t('location_add.buttons.removeTerminal', 'Remove')}
    </Button>
  );

  const title = (
    <Text as="div" variant="subtitle.section">
      {index + 1}. {t('location_add.form.deviceTypeOptions.pos', 'Terminal')}
    </Text>
  );

  return (
    <VStack align="stretch" spacing={4} key={`POS-field-${index}`}>
      {status ? (
        <Flex>
          {title}
          <Spacer />
          {status}
        </Flex>
      ) : (
        title
      )}
      {transfer}
      {config.deviceType.visible && (
        <SelectControl
          disabled={isDisabled}
          readonly={isReadOnly}
          mandatory={config.deviceType.mandatory}
          name={`${name}deviceType`}
          id={`${name}deviceType`}
          // id={`${idPrefix}${name}deviceType`}
          placeholder={t('location_add.form.deviceType.placeholder', 'Device type')}
          title={t('location_add.form.deviceType.label', 'Device type')}
          notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
          options={devices}
        />
      )}
      {config.terminalId.visible && (
        <InputControl
          disabled={isDisabled}
          readonly={isReadOnly}
          type="text"
          mandatory={config.terminalId.mandatory}
          name={`${name}terminalId`}
          id={`${idPrefix}${name}terminalId`}
          placeholder={
            isAppType
              ? t('location_add.form.applicationId.placeholder', 'Assigned application ID')
              : t('location_add.form.terminalId.placeholder', 'Terminal ID')
          }
          title={
            isAppType
              ? t('location_add.form.applicationId.label', 'Assigned application ID')
              : t('location_add.form.terminalId.label', 'Terminal ID')
          }
        />
      )}
      {config.email.visible && isAppType && (
        <InputControl
          disabled={isDisabled}
          readonly={isReadOnly}
          type="text"
          mandatory={config.email.mandatory || isAppType}
          name={`${name}email`}
          id={`${idPrefix}${name}email`}
          placeholder={t('location_add.form.email.placeholder', 'Email')}
          title={t('location_add.form.email.label', 'Email')}
        />
      )}
      {isBoInfoVisible && (
        <>
          <InputControl
            disabled
            readonly={isReadOnly}
            type="text"
            mandatory
            name={`${name}masterId`}
            id={`${idPrefix}${name}masterId`}
            placeholder={t('location_add.form.masterId.placeholder', 'Master ID')}
            title={t('location_add.form.masterId.label', 'Master ID')}
          />
          <InputControl
            disabled
            readonly={isReadOnly}
            type="text"
            mandatory
            name={`${name}terminalCode`}
            id={`${idPrefix}${name}terminalCode`}
            placeholder={t('location_add.form.terminalCode.placeholder', 'Terminal code')}
            title={t('location_add.form.terminalCode.label', 'Terminal code')}
          />
        </>
      )}
      {config.products.visible && (
        <VStack align="stretch" spacing={2} bgColor="semantic.surface.1" p={4}>
          <Flex gap={4} justify="space-between" width="100%" alignItems="center">
            <Text as="div" variant="subtitle.section">
              {t(
                'location_add.sectionTexts.addTerminal.numberOfAcceptedProducts',
                'Accepted products (%number%)',
                { number: productsCount },
              )}
            </Text>
            <Box>
              <IconButton
                aria-label="chevron"
                icon={
                  <SvgSpriteConsumer
                    size={24}
                    svgId={productsExpanded ? 'chevronUp24' : 'chevronDown24'}
                  />
                }
                onClick={toggleProducts}
                size="md"
                variant="icon"
              />
            </Box>
          </Flex>
          {productsExpanded && (
            <>
              {products.length === 0 && (
                <Text color="semantic.text.tertiary">
                  {t(
                    'location_add.sectionTexts.addTerminal.noProductsAvailable',
                    'There are no products to be added at the moment. Please add card product to contract and/or location first.',
                  )}
                </Text>
              )}
              <CheckboxGroupControl
                disabled={isProductsDisabled}
                mandatory
                name={`${name}productIds`}
                id={`${idPrefix}${name}products`}
                options={products}
                renderLeftElement={ProductWithLogo}
              />
            </>
          )}
        </VStack>
      )}
      {children ? (
        <Flex justify="space-between">
          {children}
          {onRemove && removeButton}
        </Flex>
      ) : (
        onRemove && removeButton
      )}
      {showDivider && <Divider look={DividerLook.SECONDARY} variant="solid" />}
    </VStack>
  );
};

export default withTranslations(POSRow);
